export default {
  "container": "qyl",
  "form-container": "qyX",
  "preview-container": "qyC",
  "pdf-preview-container": "qyk",
  "content": "qyJ",
  "title": "qys",
  "tabs-container": "qyF",
  "close-button": "qyg btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qyD",
  "header": "qyV",
  "without-tabs": "qyL"
};
