export default {
  "guests": "qYU",
  "guests--upsell-screen": "qYj",
  "no-members": "qYW",
  "teams-header": "qYu",
  "empty": "qhS",
  "invite-text": "qhc",
  "body": "qhq",
  "members": "qhZ",
  "members-list": "qhR",
  "members-title": "qhQ caption-bold",
  "member": "qhe",
  "details": "qhB"
};
