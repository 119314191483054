export default {
  "details": "qKx",
  "header": "qKw",
  "avatar": "qKN mr-16",
  "header-beneficiary": "qKv",
  "header-beneficiary-activity": "qKo",
  "header-beneficiary-details": "qKn",
  "header-beneficiary-details-recurring": "qKi",
  "infos": "qKA",
  "infos-title": "qKY",
  "infos-empty": "qKh",
  "infos-list": "qKf",
  "infos-list-item": "qKK",
  "infos-list-item-left": "qKG",
  "infos-list-item-right": "qKr",
  "infos-list-item-attachment": "qKb",
  "infos-transactions": "qKy",
  "actions": "qKU"
};
