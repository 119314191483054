export default {
  "container": "qre",
  "content": "qrB",
  "logos": "qrE",
  "logo": "qrd",
  "animation": "qrI",
  "title": "qrt title-2 mb-32",
  "avatar": "qrM",
  "actions": "qrP mb-32",
  "footer": "qrl body-2"
};
