export default {
  "title": "ZSS title-3",
  "personal-profile-container": "ZSc",
  "personal-profile-section": "ZSq",
  "member-info-title": "ZSZ",
  "kyc-complete-profile": "ZSR",
  "info-fields": "ZSQ",
  "field": "ZSe",
  "address-fields": "ZSB",
  "field-span": "ZSE",
  "input": "ZSd",
  "cancel": "ZSI"
};
