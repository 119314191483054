export default {
  "mandates": "qKj",
  "mandates-empty": "qKW",
  "header": "qKu",
  "header-empty": "qGS",
  "header-content": "qGc",
  "search": "qGq",
  "search-bar": "qGZ",
  "search-spinner": "qGR",
  "body": "qGQ",
  "isEmpty": "qGe",
  "left-section": "qGB",
  "mandates-list": "qGE",
  "mandate-suspended": "qGd",
  "list-title": "qGI caption-bold",
  "list-empty": "qGt",
  "empty-illustration": "qGM",
  "empty-title": "qGP title-3",
  "empty-description": "qGl body-2",
  "body-details": "qGX",
  "l-app-content__page-header": "qGC",
  "pagination-footer": "qGk"
};
