export default {
  "member-details": "qYJ",
  "header": "qYs",
  "header-img": "qYF",
  "invited-persona": "qYg",
  "revoked-persona": "qYD",
  "invited-icon": "qYV",
  "revoked-icon": "qYL",
  "dropdown": "qYT",
  "header-body": "qYz",
  "name": "qYa title-3",
  "email": "qYH caption-bold",
  "actions": "qYO body-2",
  "actions-cards": "qYm",
  "actions-transactions": "qYp",
  "body": "qYx",
  "expense-permissions-section": "qYw",
  "body-title": "qYN title-4",
  "body-attr": "qYv",
  "body-label": "qYo",
  "attr-buttons": "qYn",
  "body-role": "qYi small",
  "details-actions": "qYA",
  "actions-item": "qYY",
  "disclaimer": "qYh",
  "permissions": "qYf",
  "permission": "qYK",
  "permission-label": "qYG",
  "permission-check": "qYr",
  "permission-missing": "qYb",
  "editable-section": "qYy"
};
