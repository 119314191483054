export default {
  "page-wrapper": "qnf",
  "container": "qnK",
  "spinner-container": "qnG",
  "left-panel": "qnr",
  "form": "qnb",
  "left-content": "qny",
  "form-footer": "qnU",
  "delete-button": "qnj",
  "header": "qnW",
  "preview-container": "qnu",
  "email-preview": "qiS",
  "close-button": "qic btn btn--icon-only btn--tertiary btn--large"
};
