export default {
  "page-wrapper": "Zcp",
  "content-wrapper": "Zcx",
  "content": "Zcw",
  "period-field": "ZcN",
  "format-options": "Zcv",
  "format-option": "Zco",
  "format-radio": "Zcn",
  "format-description": "Zci body-2"
};
