export default {
  "multi-transfer": "Zqy",
  "container": "ZqU",
  "title": "Zqj",
  "subtitle": "ZqW",
  "form": "Zqu",
  "form-title": "ZZS",
  "files-buttons": "ZZc",
  "files-buttons-item": "ZZq",
  "selected": "ZZZ"
};
