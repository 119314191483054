export default {
  "container": "qUd",
  "form-container": "qUI",
  "title": "qUt",
  "title-border": "qUM",
  "tabs-container": "qUP",
  "preview-container": "qUl",
  "email-preview-container": "qUX",
  "close-button": "qUC btn btn--icon-only btn--tertiary btn--large"
};
