export default {
  "container": "qWa",
  "wrapper": "qWH",
  "grey-header-block": "qWO",
  "connect-app-header": "qWm",
  "connect-app-header-link": "qWp",
  "connect-app-header-content": "qWx",
  "connect-app-header-content-left": "qWw",
  "application-avatar": "qWN",
  "connect-app-header-cta": "qWv",
  "connect-app-header-meta": "qWo",
  "connect-app-content": "qWn",
  "connect-app-description": "qWi",
  "title-wrapper": "qWA",
  "title": "qWY title-1",
  "tag-line": "qWh body-2",
  "connect-app-body": "qWf"
};
