export default {
  "page-header": "ZZJ",
  "header-breadcrumb": "ZZs",
  "tabs-container": "ZZF",
  "bottom-border": "ZZg",
  "tabs-separator": "ZZD",
  "previous-request": "ZZV",
  "body-link": "ZZL",
  "border-top": "ZZT"
};
