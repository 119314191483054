export default {
  "vat-number": "qum body-2",
  "banner": "qup",
  "container": "qux",
  "organization-profile": "quw",
  "input": "quN",
  "medium-input": "quv medium",
  "small-input": "quo small",
  "actions": "qun",
  "company-info": "qui",
  "company-info-section": "quA",
  "company-address-section": "quY",
  "company-country": "quh",
  "company-billing-email": "quf",
  "company-address": "quK"
};
