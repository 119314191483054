export default {
  "dropdown-icon": "ZqH",
  "dropdown": "ZqO",
  "download-button": "Zqm",
  "connections-banner": "Zqp",
  "recommendation-cards": "Zqx",
  "wrapper-with-cards": "Zqw",
  "transactions-wrapper": "ZqN",
  "separator": "Zqv",
  "with-transaction": "Zqo",
  "annual-billing-banner": "Zqn",
  "mt-132": "Zqi",
  "transactions-sidebar": "ZqA",
  "empty-state-wrapper": "ZqY"
};
