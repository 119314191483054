/* eslint-disable @qonto/no-async-action, @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { dropTask, keepLatestTask, restartableTask, task, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';
import { equal, reads } from 'macro-decorators';
import { TrackedArray } from 'tracked-built-ins';

import { NO_PERIOD_ID } from 'qonto/constants/budget';
import { LAYOUT } from 'qonto/constants/empty-states/system';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/transactions';
import { ROLES } from 'qonto/constants/membership';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { DEFAULT_SEARCH_INCLUDES, DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { OPERATION_TYPES } from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import loadAllPaginated from 'qonto/routes/budgets/list/load-all-paginated';
import { queryParamsFilterGroup } from 'qonto/routes/transactions/index/route';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { parseContentDisposition } from 'qonto/utils/parse-content-disposition';
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';
import { deserializeArrayQP, serializeArrayQP } from 'qonto/utils/query-params';
import getResourcesIdsToFetch from 'qonto/utils/transaction-resources-ids-to-fetch';

const MAX_ATTACHMENTS = 5;
const ATTACHMENT_TYPE_INVOICE = 'receivable_invoice';

export const defaultPagination = { page: 1, per_page: 25 };

const defaultQuery = {
  sort: { property: 'emitted_at', direction: 'desc' },
  search: '',
  pagination: defaultPagination,
};

export default class TransactionsIndexController extends Controller {
  @service abilities;
  @service errors;
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service attachmentsManager;
  @service store;
  @service toastFlashMessages;
  @service segment;
  @service modals;
  @service sentry;
  @service subscriptionManager;
  @service flowLinkManager;
  @service notifierCounterManager;
  @service emptyStates;
  @service networkManager;
  @service bankAccount;

  @tracked isAttachmentRequested = false;
  @tracked showHelpSection = false;
  @tracked isBudgetSupervisor = false;

  queryParams = [
    { _items: 'items' },
    { _teamIds: 'teamIds' },
    { bankAccounts: 'bank-accounts' },
    'card',
    'filter',
    'highlight',
    'initiator',
    'mandate',
    { onlyMe: 'only-me' },
    'page',
    'per_page',
    'preset',
    'query',
    { settledAtFrom: 'settled-at-from' },
    { showSuggestedAttachments: { type: 'boolean' } },
    'sort_by',
  ];
  // used for query parameters passed through redirections
  queryParamsFilter = [
    'card',
    'filter',
    'initiator',
    'mandate',
    'preset',
    'settledAtFrom',
    '_teamIds',
  ];
  @tracked _items = '';
  @tracked _teamIds = '';
  @tracked bankAccounts = '';
  @tracked card = '';
  @tracked filter = '';
  @tracked highlight = '';
  @tracked initiator = '';
  @tracked mandate = '';
  @tracked onlyMe = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked preset = '';
  @tracked query = '';
  @tracked settledAtFrom = '';
  @tracked showSuggestedAttachments = false;
  @tracked sort_by = DEFAULT_SORT_BY;

  // Used to keep track of the promise-modal reference,
  // so it can be closed when leaving the route.
  exportModal;

  @tracked transactions = [];
  @tracked updatedLabels = new TrackedArray();
  @tracked updatedCategory;

  @tracked meta = null;
  @tracked _filterGroup;
  @tracked hasCardsToDisplay = null;

  @reads('localeManager.locale') locale;
  @reads('organizationManager.organization') organization;

  get items() {
    return deserializeArrayQP(this._items);
  }
  set items(arr) {
    this._items = serializeArrayQP(arr);
  }

  get teamIds() {
    return deserializeArrayQP(this._teamIds);
  }
  set teamIds(arr) {
    this._teamIds = serializeArrayQP(arr);
  }

  get highlightedItem() {
    return this.highlight ? this.store.peekRecord('transaction', this.highlight) : null;
  }

  get filterGroup() {
    let savedFilterGroup = safeLocalStorage.getItem(
      `${this.organizationManager.organization.id}-transactions-filter-group`
    );
    savedFilterGroup = savedFilterGroup ? JSON.parse(savedFilterGroup) : undefined;

    return this._filterGroup === undefined ? savedFilterGroup : this._filterGroup;
  }

  get excludedProperties() {
    return this.abilities.can('assign category') ? 'activity_tag' : '';
  }

  set filterGroup(filterGroup) {
    this._filterGroup = filterGroup;
  }

  /**
   * Extracts relevant query parameters listed in the queryParamsFilter array from this instance
   * and prepares them to be used in the queryParamsFilterGroup method to create a filter group object.
   * @returns {Object} - A queryParams object with the extracted parameters.
   */
  getRedirectionQueryParams() {
    let queryParams = {};

    for (let param of this.queryParamsFilter) {
      if (this[param] !== undefined) {
        queryParams[param] = this[param];
      }
    }

    return queryParams;
  }

  /**
   * Create the filters object from query params.
   * @returns an object with the proper structure for the filtering behavior
   */
  get queryParamsFilters() {
    let { onlyMe } = this;
    let queryParams = this.getRedirectionQueryParams();

    // This feature is active only for missing-attachments filter, related to Receipt Reminders feature
    if (queryParams.filter === 'missing-attachments') {
      if (!queryParams.initiator && onlyMe === 'true') {
        queryParams.initiator = this.organizationManager.membership.id;
      }
      return queryParamsFilterGroup(queryParams, {
        isPagoPaFilterEnabled: this.isPagoPaFilterEnabled,
      });
    }

    return null;
  }

  get isPagoPaFilterEnabled() {
    return this.abilities.can('view filter pagopa');
  }

  get hasFilters() {
    return Boolean(
      this.query ||
        this.filterGroup ||
        this.card ||
        this.initiator ||
        this.filter ||
        this.mandate ||
        this.teamIds?.length ||
        this.preset
    );
  }

  get queryOptions() {
    let { query, sort_by } = this;
    let [property, direction] = (sort_by || DEFAULT_SORT_BY).split(':');

    return {
      sort: { property, direction },
      search: query,
      pagination: defaultPagination,
    };
  }

  get isInternalTransfersEnabled() {
    let canCreateInternalTransfer = this.abilities.can('create internal transfer');
    let { hasMultipleActiveCurrentRemuneratedAccounts } = this.organization;

    return canCreateInternalTransfer && hasMultipleActiveCurrentRemuneratedAccounts;
  }

  get showMultipleDownload() {
    return !this.bankAccounts && this.organization.bankAccounts.length > 1;
  }

  get selectedAccount() {
    return (
      this.organization.bankAccounts.find(({ id }) => id === this.bankAccounts) ||
      this.organizationManager.currentAccount
    );
  }

  get canAccessAccountDetails() {
    return (
      this.abilities.can('access bank-account') &&
      this.selectedAccount &&
      !this.selectedAccount.isExternalAccount
    );
  }

  @equal('selectedAccount.status', 'closed') selectedAccountIsClosed;

  get highlightableItems() {
    let { highlight, transactions } = this;

    if (highlight && !this.isLoading) {
      let transaction = this.store.peekRecord('transaction', highlight);
      if (transaction && !transactions.includes(transaction)) {
        return [...transactions, transaction];
      }
    }

    return transactions;
  }

  get emptyStateOptions() {
    if (this.isLoading || this.fetchDataTask.last.isError) {
      return false;
    }

    let roleBasedTranslations = {
      admin: {
        title: this.intl.t('empty-states.transactions.admin.title'),
        subtitle: this.intl.t('empty-states.transactions.admin.subtitle'),
        subtitleMulti: this.intl.t('empty-states.transactions.admin.subtitle-multi'),
        button: this.intl.t('empty-states.transactions.admin.button'),
      },
      manager: {
        title: this.intl.t('empty-states.transactions.manager.title'),
        subtitle: this.intl.t('empty-states.transactions.manager.subtitle'),
        button: this.intl.t('empty-states.transactions.manager.button'),
      },
      employee: {
        title: this.intl.t('empty-states.transactions.employee.title'),
        subtitle: this.intl.t('empty-states.transactions.employee.subtitle'),
      },
      reporting: {
        title: this.intl.t('empty-states.transactions.reporting.title'),
        subtitle: this.intl.t('empty-states.transactions.reporting.subtitle'),
      },
      owner: {
        title: this.intl.t('empty-states.transactions.owner.title'),
        subtitle: this.intl.t('empty-states.transactions.owner.subtitle'),
        button: this.intl.t('empty-states.transactions.owner.button'),
      },
    };

    if (this.canDisplayRecommendationCards) {
      roleBasedTranslations = {
        admin: {
          title: this.intl.t('empty-states.transactions.get-started-actions.admin.title'),
          subtitle: this.intl.t('empty-states.transactions.get-started-actions.admin.subtitle'),
          subtitleMulti: this.intl.t(
            'empty-states.transactions.get-started-actions.admin.subtitle-multi'
          ),
          button: this.intl.t('empty-states.transactions.get-started-actions.admin.button'),
        },
        manager: {
          title: this.intl.t('empty-states.transactions.get-started-actions.manager.title'),
          subtitle: this.intl.t('empty-states.transactions.get-started-actions.manager.subtitle'),
          button: this.intl.t('empty-states.transactions.get-started-actions.manager.button'),
        },
        employee: {
          title: this.intl.t('empty-states.transactions.get-started-actions.employee.title'),
          subtitle: this.intl.t('empty-states.transactions.get-started-actions.employee.subtitle'),
        },
        reporting: {
          title: this.intl.t('empty-states.transactions.get-started-actions.reporting.title'),
          subtitle: this.intl.t('empty-states.transactions.get-started-actions.reporting.subtitle'),
        },
        owner: {
          title: this.intl.t('empty-states.transactions.get-started-actions.owner.title'),
          subtitle: this.intl.t('empty-states.transactions.get-started-actions.owner.subtitle'),
          button: this.intl.t('empty-states.transactions.get-started-actions.owner.button'),
        },
      };
    }

    let { role, admin } = this.organizationManager.membership;

    if (!this.transactions.length && !this.hasFilters) {
      let title = this.selectedAccountIsClosed
        ? this.intl.t('empty-states.transactions.closed-account.title')
        : roleBasedTranslations[role].title;
      let activeAccountSubtitle =
        this.isInternalTransfersEnabled || !admin
          ? roleBasedTranslations[role].subtitle
          : roleBasedTranslations[role].subtitleMulti;

      let subtitle = this.selectedAccountIsClosed
        ? this.intl.t('empty-states.transactions.closed-account.subtitle')
        : activeAccountSubtitle;

      return {
        title,
        subtitle,
        lottieSrc: '/lotties/empty-state/no-transaction.json',
        button: roleBasedTranslations[role].button,
      };
    }

    return false;
  }

  get isExistingClient() {
    return this.subscriptionManager.currentPricePlan?.disabled;
  }

  get listEmptyState() {
    if (this.isLoading) {
      return {
        legend: '',
        title: '',
        empty: false,
        error: false,
        isLoading: true,
      };
    }

    if (this.fetchDataTask.last.isError) {
      return {
        legend: this.intl.t('error_states.transactions.legend'),
        title: this.intl.t('error_states.transactions.title'),
        empty: false,
        error: true,
        isLoading: false,
      };
    }

    if (!this.transactions.length && this.hasFilters) {
      return {
        legend: '',
        illuPath: '',
        title: '',
        empty: true,
        error: false,
        isLoading: false,
      };
    }

    return {
      legend: '',
      illuPath: '',
      title: '',
      empty: false,
      error: false,
      isLoading: false,
    };
  }

  get canDisplayBanners() {
    return this.canDisplayRecommendationCards && !this.isEmptyStatePreviewLayout;
  }

  get canDisplayRecommendationCards() {
    if (this.abilities.can('view overview')) {
      return false;
    }

    if (this.isLoading || this.fetchDataTask.last.isError) {
      return false;
    }

    // this.notifierCounterManager.counter is used inside GetStartedActions::RecommendationCards for internal conditions
    // without this check here there are issue with displaying banner
    if (
      this.selectedAccountIsClosed ||
      this.hasFilters ||
      Boolean(this.bankAccounts) ||
      !this.notifierCounterManager.counter
    ) {
      return false;
    }

    if (this.abilities.cannot('view get started actions onboarding')) {
      return false;
    }

    return true;
  }

  @action
  trackCTAEvent(origin) {
    this.emptyStates.trackCta(this.emptyStateOptionsRevamped, origin);
  }

  @action
  onCardsDisplay(value) {
    this.hasCardsToDisplay = value;
  }

  fetchMembershipsTask = task(async transactions => {
    if (this.abilities.can('read membership')) {
      let { membershipsToFetch } = getResourcesIdsToFetch(transactions);
      if (membershipsToFetch?.length) {
        await this.store.query('membership', {
          organization_id: this.organizationManager.organization.id,
          filters: { ids: membershipsToFetch },
          per_page: this.per_page,
        });
      }
    }
  });

  fetchCountDataTask = task(async () => {
    return await this.store.adapterFor('transaction').insights({
      organization_id: this.organizationManager.organization.id,
    });
  });

  get isEmptyGlobally() {
    let {
      completed = 0,
      pending = 0,
      reversed = 0,
      declined = 0,
    } = this.fetchCountDataTask.last?.value || {};
    return completed + pending + reversed + declined === 0;
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateOptionsRevamped?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get emptyStateOptionsRevamped() {
    if (
      this.isLoading ||
      this.fetchDataTask.last?.isError ||
      !variation('feature--boolean-empty-state-revamp')
    ) {
      return;
    }

    let canQualifyForAccounting =
      this.abilities.can('qualify for accounting transactions') &&
      ![ROLES.ADMIN, ROLES.OWNER].includes(this.organizationManager.membership.role);

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.meta?.total_count === 0,
      hasActiveFilterOrSearch: this.hasFilters,
      config: getEmptyStateConfig(this.intl),
      abilities: {
        canCreateInvite: this.abilities.can('create invites'),
        canAccessBankAccount: this.abilities.can('access bank-accounts'),
        canReadBankAccount: this.abilities.can('read bank-accounts'),
        canQualifyForAccounting,
      },
      customInputs: {
        isBankAccountClosed: this.selectedAccountIsClosed,
        hasMultiAccountsFeature: this.subscriptionManager.hasFeature('multi_accounts'),
      },
    });
  }

  get isLoading() {
    return this.fetchDataTask.isRunning || this.fetchCountDataTask.isRunning;
  }

  fetchDataTask = task(async (params, enable, filterGroup = this.filterGroup) => {
    await this.fetchTransactionsTask.perform(params, filterGroup);

    if (
      this.abilities.can('use budget') &&
      this.organizationManager.membership.role === ROLES.MANAGER
    ) {
      let budgets = await loadAllPaginated(page =>
        this.store.query('budget', {
          filters: { archived: false },
          page,
        })
      );
      for (let budget of budgets) {
        if (budget.supervisorIds.includes(this.organizationManager.membership.id)) {
          this.isBudgetSupervisor = true;
          break;
        }
      }
    } else {
      this.isBudgetSupervisor = true;
    }
  });

  get bankAccountFilter() {
    let { bankAccounts } = this;
    let bankAccountFilter;
    if (bankAccounts) {
      bankAccountFilter = { bank_account_ids: bankAccounts.split(',') };
    }

    return bankAccountFilter;
  }

  fetchTransactionsTask = dropTask(
    waitFor(async (params, filterGroup) => {
      this.hasCardsToDisplay = null;
      let { page, per_page, sort_by, query: search } = params;
      let [property, direction] = (sort_by || DEFAULT_SORT_BY).split(':');

      page = page ?? 1;
      per_page = per_page ?? 25;
      search = search ?? '';

      let query = {
        includes: DEFAULT_SEARCH_INCLUDES,
        search,
        sort: { property, direction },
        pagination: { page, per_page },
        organization_id: this.organization.id,
        ...(filterGroup ? { filter_group: filterGroup } : {}),
        ...this.bankAccountFilter,
      };

      let { preset } = this.getRedirectionQueryParams();

      if (filterGroup && !preset) {
        safeLocalStorage.setItem(
          `${this.organizationManager.organization.id}-transactions-filter-group`,
          JSON.stringify(filterGroup)
        );
      } else {
        safeLocalStorage.removeItem(
          `${this.organizationManager.organization.id}-transactions-filter-group`
        );
      }

      let { transactions, meta } = await this.search(query);
      try {
        await this.fetchMembershipsTask.perform(transactions);
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));

        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(
            new Error(`Transaction related memberships fetch fails with status ${error.status}`, {
              cause: error,
            })
          );
        }
      }

      this.transactions = transactions;
      this.meta = meta;

      this.hasCardsToDisplay = this.canDisplayRecommendationCards ? null : false;
    })
  );

  onPeriodOptionSelectionTask = restartableTask(async option => {
    let periodId = option.id === NO_PERIOD_ID ? null : option.id;
    return await this.store
      .adapterFor('budget')
      .allocateTransactionToPeriod({ periodId, transactionId: this.highlightedItem.id });
  });

  searchTask = restartableTask(async query => {
    await timeout(DEBOUNCE_MS);

    if (query.includes('🥚')) {
      return this.modals.open('easter-egg', {
        fullscreen: true,
      });
    }
    this.query = query;
    this.page = 1;
    this.highlight = null;
  });

  addAttachmentTask = task(async (model, file, callback) => {
    let { page, per_page, query, sort_by } = this;
    let queryParams = this.getRedirectionQueryParams();
    let params = {
      page,
      per_page,
      query,
      sort_by,
      ...this.bankAccountFilter,
    };

    let filterGroup =
      this.filterGroup ||
      queryParamsFilterGroup(queryParams, {
        isPagoPaFilterEnabled: this.isPagoPaFilterEnabled,
      });

    let result;
    try {
      result = await this.attachmentsManager.addAttachmentTask.perform(true, model, file);

      this.segment.track('transaction_attachment_uploaded');

      callback?.();

      await this.fetchTransactionsTask.perform(params, filterGroup);
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(
          new Error(`Addition of the transaction's attachment fails with status ${error.status}`, {
            cause: error,
          })
        );
      }
    }

    return result;
  });

  removeAttachmentConfirmTask = task(async (model, file) => {
    let { page, per_page, query, sort_by } = this;
    let queryParams = this.getRedirectionQueryParams();
    let params = {
      page,
      per_page,
      query,
      sort_by,
      ...this.bankAccountFilter,
    };

    let filterGroup =
      this.filterGroup ||
      queryParamsFilterGroup(queryParams, {
        isPagoPaFilterEnabled: this.isPagoPaFilterEnabled,
      });

    let attachment = this.attachmentsManager.getAttachmentByFile(model, file);
    let isReceivableInvoiceAttachment = attachment?.attachmentType === ATTACHMENT_TYPE_INVOICE;

    try {
      await this.attachmentsManager.removeAttachmentTask.perform(true, model, file);
      await this.fetchTransactionsTask.perform(params, filterGroup);

      if (isReceivableInvoiceAttachment) {
        this.toastFlashMessages.toastSuccess(
          this.intl.t('transactions.modals.success.unlinked-invoice')
        );
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  downloadProofTask = dropTask(async transferProxy => {
    let transfer = await transferProxy;

    this.segment.track('transaction-details_download-pop_clicked', {
      transfer_type: ['fx_scheduled', OPERATION_TYPES.INTERNATIONAL_OUT].includes(
        transfer.operationType
      )
        ? 'swift'
        : 'sepa',
    });

    if (transfer.proofPdfUrl) {
      if (transfer.isInternationalOut) {
        try {
          await this.downloadInternationalProofTask.perform(transfer);
        } catch (error) {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
        }
        return;
      }

      window.location.href = transfer.proofPdfUrl;
      return;
    }

    if (transfer.isInternationalOut) {
      return;
    }

    try {
      await transfer.generateProof();
      window.location.href = transfer.proofPdfUrl;
    } catch (error) {
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  downloadInternationalProofTask = task(async transfer => {
    let response = await this.networkManager.rawRequest(transfer.proofPdfUrl);
    let blob = await response.blob();
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = parseContentDisposition(response.headers).get('filename');
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(link.href);
  });

  async search(query) {
    let result = await this.store.adapterFor('transaction').search(query);
    return result;
  }

  _extractIDsFromLabelsArray(labelsArray) {
    return labelsArray.map(item => item.get('id'));
  }

  saveModelTask = keepLatestTask(async (model, attribute, value) => {
    if (model && attribute) {
      if (typeof attribute === 'object') {
        model.setProperties(attribute);
      } else {
        model.set(attribute, value);
      }
    }
    await timeout(500);

    let result;
    try {
      result = await model.save();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(
          new Error(`Saving of the transaction's ${attribute} fails with status ${error.status}`, {
            cause: error,
          })
        );
      }
    }

    return result;
  });

  @action
  openSuggestedAttachmentsModal() {
    if (variation('feature--boolean-improve-manual-matching')) {
      return this.openAttachmentsModal();
    }

    let { attachmentIds, attachmentSuggestionIds } = this.highlightedItem;

    if (attachmentIds.length < MAX_ATTACHMENTS && attachmentSuggestionIds.length) {
      return this.openAttachmentsModal();
    }

    this.showSuggestedAttachments = false;
  }

  openAttachmentsModal() {
    this.modals.open('attachments/attachments-suggested/modal', {
      transaction: this.highlightedItem,
      onClose: close => {
        close();
        this.showSuggestedAttachments = false;
      },
    });
    this.showSuggestedAttachments = true;
  }

  @action
  async handleSelectTag(model, tagCode, source) {
    if (!source) {
      this.segment.track('transaction_category_selected', {
        source: 'transaction_details',
      });
    }
    try {
      await model.updateActivityTag(tagCode);
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  get closeSidebarId() {
    return 'close-sidebar';
  }

  get hasNoQuoteAndInvoice() {
    return this.model.fetchHasNoQuoteAndInvoiceTask.lastSuccessful?.value ?? false;
  }

  @action
  handlePageChange(value) {
    this.page = value;
    this.highlight = null;
  }

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
    this.highlight = null;
  }

  @action
  updateHighlightedItem(itemId) {
    // If the user clicks on the same transaction, we don't want to refetch data.
    if (itemId === this.highlight) {
      return;
    }

    this.highlight = itemId;
    this.showHelpSection = false;

    if (!this.model.fetchHasNoQuoteAndInvoiceTask.lastSuccessful) {
      this.model.fetchHasNoQuoteAndInvoiceTask.perform().catch(ignoreCancelation);
    }
  }

  @action
  updateSelectedItems(newItems, { isAllItemsSelected = false, isOneItemSelected = false } = {}) {
    this.items = newItems;

    if (isAllItemsSelected) {
      this.segment.track('history_bulk_selection_clicked', {
        number_of_transactions: newItems.length,
      });
    } else if (isOneItemSelected) {
      this.segment.track('history_transactions_selected');
    }
  }

  handleRemoveAttachmentTask = dropTask(async (model, file) => {
    let attachment = this.attachmentsManager.getAttachmentByFile(model, file);

    let isReceivableInvoiceAttachment = attachment?.attachmentType === ATTACHMENT_TYPE_INVOICE;

    if (isReceivableInvoiceAttachment) {
      return await this.removeAttachmentConfirmTask.perform(model, file);
    }

    this.modals.open('attachments/confirm-delete-modal', {
      file,
      model,
      confirmTask: this.removeAttachmentConfirmTask,
    });
  });

  @action
  handleDiscardBulkEdit() {
    this.updatedCategory = null;
    this.updatedLabels = new TrackedArray();
    this.updateSelectedItems([]);
  }

  @action
  handleUpdatedCategory(category) {
    this.segment.track('transaction_category_selected', {
      source: 'bulk_details',
    });

    this.updatedCategory = category;
  }

  markAsReviewTask = task(async (isQualified, transactionIds) => {
    let organizationId = this.organization.id;
    let transaction = this.store.createRecord('transaction');
    let { page, per_page, query, sort_by, filterGroup } = this;
    let params = { page, per_page, query, sort_by };

    try {
      if (isQualified) {
        await transaction.markAsQualify({ organizationId, transactionIds });
      } else {
        await transaction.markAsDisqualify({ organizationId, transactionIds });
      }

      if (Array.isArray(filterGroup?.expressions)) {
        let hasVerificationFilter = filterGroup.expressions.some(
          ({ expressions }) =>
            Array.isArray(expressions) &&
            expressions.some(({ property }) => property === 'qualified_for_accounting')
        );
        if (hasVerificationFilter) {
          await this.fetchTransactionsTask.perform(params, filterGroup);
        }
      }
    } finally {
      transaction.deleteRecord();
    }
  });

  handleSubmitBulkEditTask = dropTask(async selectedItems => {
    let { updatedCategory, isAttachmentRequested, updatedLabels } = this;

    if (!updatedCategory && !isAttachmentRequested && !updatedLabels?.length) {
      return;
    }

    let editingItems = selectedItems;
    let { currentAccount } = this.organizationManager;

    let bulkActionRecord = await this.store.createRecord('bulk-action', {
      bankAccountId: currentAccount.id,
      organizationId: this.organization.id,
      class: 'transaction',
      id: crypto.randomUUID(),
      ids: editingItems.map(item => item.get('id')),
      fields: {
        activity_tag: updatedCategory,
        request_attachment: isAttachmentRequested,
        label_ids: this._extractIDsFromLabelsArray(updatedLabels),
      },
    });
    await bulkActionRecord.save();
    this.updatedCategory = null;
    this.isAttachmentRequested = false;
    this.updatedLabels = new TrackedArray();
  });

  handleSaveLabelTask = task(async (transaction, labelList, label, source) => {
    transaction.labels = getLabelsToUpdate(transaction.labels, labelList, label);

    let result;
    try {
      let trackingEventName = 'transaction_analytic_label_edited';

      if (!label) {
        trackingEventName = 'transaction_analytic_label_deleted';
      }
      this.segment.track(trackingEventName, {
        source,
      });

      result = await transaction.save();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(
          new Error(`Saving of the transaction's labels fails with status ${error.status}`, {
            cause: error,
          })
        );
      }
    }

    return result;
  });

  @action
  handleSortBy(sortDefinition) {
    this.highlight = null;
    this.items = [];
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  /**
   * This function takes care of the resetting of the query params variables
   * according to the applied filter.
   */
  resetQueryParams() {
    if (this.filter !== 'missing-attachments') {
      this.settledAtFrom = '';
      this.onlyMe = '';
    }
  }

  @action
  applyFilters({ sort, filter_group, search, pagination } = defaultQuery, presetId) {
    let { property, direction } = sort;
    let page = pagination?.page;
    let per_page = pagination?.per_page;
    let sortBy = `${property}:${direction}`;

    this.page = page;
    this.per_page = per_page;
    this.query = search;
    this.sort_by = sortBy;
    this.filterGroup = filter_group || null;
    this.filter = undefined;
    this.preset = presetId;
    this.highlight = undefined;
    this.initiator = undefined;
    this.card = undefined;

    this.resetQueryParams();

    let params = { page, per_page, query: search, sort_by: sortBy, ...this.bankAccountFilter };

    this.fetchDataTask
      .perform(params, true, filter_group)
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  handleRequestAttachmentTask = dropTask(async model => {
    try {
      await model.requestAttachment();
      let message = this.intl.t('toasts.attachment_requested');
      this.toastFlashMessages.toastInfo(message, 'attachment');
    } catch {
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message, 'attachment');
    }
  });

  @action
  async openExportModal() {
    this.segment.track('export_transactions_clicked');
    let queryParams = this.getRedirectionQueryParams();

    if (queryParams.preset) {
      let presets = await this.store.query('search-preset', {
        organization_id: this.organizationManager.organization.id,
      });

      let selectedPreset = presets.find(({ id }) => id === queryParams.preset);
      this.filterGroup = selectedPreset?.query?.filter_group;
    }

    let [property, direction] = (this.sort_by || DEFAULT_SORT_BY).split(':');
    let filters = {
      ...this.bankAccountFilter,
      organization_id: this.organization.id,
      search: this.query,
      filter_group:
        this.filterGroup ||
        queryParamsFilterGroup(queryParams, {
          isPagoPaFilterEnabled: this.isPagoPaFilterEnabled,
        }),
      sort: { property, direction },
    };

    this.exportModal = this.modals.open('transactions/custom-exports/modal', {
      isFullScreenModal: true,
      filters,
    });
  }

  handleIbanDownloadTask = dropTask(async activeAccountId => {
    this.segment.track('transactions_empty_state_download_IBAN_clicked', {
      account_id: activeAccountId,
    });
    await this.bankAccount.downloadIbanPdf(activeAccountId);
  });
}
