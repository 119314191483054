export default {
  "wrapper": "quk",
  "integrations": "quJ",
  "description": "qus body-2",
  "api": "quF flex flex-column",
  "api-container": "qug flex large",
  "api-input": "quD large",
  "api-btn": "quV",
  "key-input": "quL"
};
