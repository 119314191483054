export default {
  "container": "qjb",
  "wrapper": "qjy",
  "grey-header-block": "qjU",
  "connect-app-header": "qjj",
  "connect-app-header-link": "qjW",
  "connect-app-header-content": "qju",
  "connect-app-header-content-default": "qWS",
  "connect-app-header-content-left": "qWc",
  "button-block": "qWq",
  "connect-app-body": "qWZ",
  "connect-app-body-left-content": "qWR",
  "connect-app-body-sidebar": "qWQ"
};
