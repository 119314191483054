export default {
  "header": "qjO",
  "content": "qjm",
  "read-only": "qjp",
  "document-collection-banner": "qjx",
  "submit-banner": "qjw",
  "review-banner": "qjN",
  "disclaimer": "qjv",
  "title": "qjo",
  "card-container": "qjn",
  "representatives-section": "qji",
  "disabled-href": "qjA"
};
