import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class AgenciaTributariaListLoadingController extends Controller {
  @service router;

  get isCompleted() {
    return this.router.currentURL.includes('completed');
  }
}
