export default {
  "accounts": "qvS",
  "header-subtitle": "qvc title-4",
  "subtitle-container": "qvq",
  "subtitle": "qvZ title-3 mb-16",
  "amount": "qvR body-2",
  "list--cashbeeSavings": "qvQ",
  "connections-banner": "qve",
  "list": "qvB",
  "icon": "qvE",
  "badge": "qvd",
  "discover-tile": "qvI"
};
