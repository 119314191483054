export default {
  "page-wrapper": "qnd",
  "back-button": "qnI",
  "abort-button": "qnt",
  "container": "qnM",
  "subtitle": "qnP",
  "button": "qnl",
  "panel-list": "qnX",
  "panel-list-item": "qnC",
  "card-illustration": "qnk"
};
