export default {
  "container": "ZSM",
  "security-method": "ZSP",
  "icon": "ZSl",
  "body": "ZSX",
  "status": "ZSC body-2",
  "device": "ZSk",
  "device-icon": "ZSJ",
  "subtitle": "ZSs body-2",
  "current": "ZSF"
};
