export default {
  "container": "qAo",
  "page-wrapper": "qAn",
  "header": "qAi",
  "back-button-wrapper": "qAA",
  "header-inner": "qAY",
  "header-main-wrapper": "qAh",
  "header-main": "qAf",
  "header-right": "qAK",
  "content-wrapper": "qAG",
  "content": "qAr",
  "content-body": "qAb",
  "sidebar": "qAy"
};
