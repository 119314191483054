/* eslint-disable @qonto/no-import-roles-constants */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ROLES } from 'qonto/constants/membership';

export default class TransfersRoute extends Route {
  @service abilities;
  @service homePage;
  @service notifierManager;
  @service organizationManager;
  @service router;
  @service store;

  get isRequester() {
    let { organization } = this.organizationManager;

    let isManagerRequester =
      this.organizationManager.membership.role === ROLES.MANAGER &&
      (organization.hasModularPricing
        ? this.abilities.cannot('create transfer')
        : this.abilities.cannot('review transfer request')) &&
      this.abilities.can('read request');

    let isEmployeeRequester =
      this.organizationManager.membership.role === ROLES.EMPLOYEE &&
      this.abilities.cannot('review transfer request') &&
      this.abilities.can('create transfer request');

    return isManagerRequester || isEmployeeRequester;
  }

  activate() {
    this.notifierManager.on('attachment.updated', this, 'updateAttachment');
  }

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    let isRequestsChildRoute = [
      'transfers.requests.requester-multi-transfer',
      'transfers.requests.multi-transfer',
    ].includes(transition.to.name);

    if (this.isRequester && !isRequestsChildRoute) {
      return this.router.replaceWith('transfers.requests.index');
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        sort_by: null,
      });
    }
  }

  deactivate() {
    this.notifierManager.off('attachment.updated', this, 'updateAttachment');
  }

  updateAttachment(payload) {
    let attachment = this.store.peekRecord('attachment', payload.object.id);
    let { data } = this.store.normalize('attachment', payload.object);
    if (attachment) {
      attachment.probativeAttachment = data.attributes.probativeAttachment;
    }
  }
}
