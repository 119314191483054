export default {
  "main-container": "qob",
  "main": "qoy",
  "main-wrapper": "qoU",
  "close-button": "qoj",
  "active-block": "qoW",
  "title": "qou",
  "list-options": "qnS",
  "card": "qnc",
  "card-container": "qnq",
  "card-image": "qnZ",
  "card-tiles": "qnR"
};
