export default {
  "signin": "Zck",
  "form": "ZcJ",
  "illustration": "Zcs",
  "purple": "ZcF",
  "mint": "Zcg",
  "mustard": "ZcD",
  "peach": "ZcV",
  "form-wrapper": "ZcL",
  "header-logo": "ZcT",
  "links": "Zcz",
  "no-account-label": "Zca",
  "spinner-container": "ZcH",
  "main-image-container": "ZcO",
  "main-image": "Zcm"
};
