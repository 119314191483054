import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

// eslint-disable-next-line @qonto/no-import-roles-constants
import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';
import {
  ONBOARDING_SOURCE,
  ONBOARDING_STEP_STATUS,
  ONBOARDING_TRACKING_EVENTS,
} from 'qonto/constants/receivable-invoice';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class AccountReceivableOnboardingFlowDataContext {
  isAccountReceivableOnboardingFlow = true;
  nextStepId = null;
  onboardingState = null;
  settings = null;
  source = null;
  legalCountry = null;
  isFrenchOrganization = null;
  isItalianOrganization = null;
  isGermanOrganization = null;
  eInvoiceActivation = null;

  constructor({ legalCountry, source }) {
    this.legalCountry = legalCountry;
    this.source = source;

    this.isFrenchOrganization = legalCountry === 'FR';
    this.isItalianOrganization = legalCountry === 'IT';
    this.isGermanOrganization = legalCountry === 'DE';
  }
}

export default class AccountReceivableOnboardingFlowSetup extends FlowSetup {
  @service router;
  @service homePage;
  @service organizationManager;
  @service abilities;
  @service store;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service segment;
  @service flow;

  constructor() {
    super(...arguments);

    this.dataContext = new AccountReceivableOnboardingFlowDataContext({
      legalCountry: this.organizationManager.organization.legalCountry,
      source: this.source,
    });
  }

  get source() {
    return this.flow.refererPage.includes('invoice-subscriptions')
      ? ONBOARDING_SOURCE.RECURRING_INVOICES
      : ONBOARDING_SOURCE.INVOICES;
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    if (this.abilities.cannot('complete accountsReceivableOnboarding')) {
      return this.homePage.visitDefaultPage();
    }

    try {
      let organization = this.organizationManager.organization;
      await organization.getAvatar();

      this.dataContext.onboardingState = await this.store.findRecord(
        'accounts-receivable-onboarding',
        organization.id
      );

      this.dataContext.settings = await this.store.findRecord(
        'receivable-invoices-settings',
        organization.id
      );

      let alreadyInvitedAccountants = await this.store.query('membership', {
        organization_id: organization.id,
        filters: {
          status: [MEMBER_STATUS.INVITED],
          roles: [ROLES.REPORTING],
        },
      });

      if (alreadyInvitedAccountants.length > 0) {
        this.dataContext.onboardingState.stepStatusAccountantAccess =
          ONBOARDING_STEP_STATUS.CONFIRMED;
      }

      if (this.dataContext.isItalianOrganization) {
        this.dataContext.eInvoiceActivation = await this.store.findRecord(
          'e-invoice-activation',
          organization.id
        );
      }
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.homePage.visitDefaultPage();
    }
  }

  onComplete() {
    // This function is executed when the user, in the last step of the flow, clicks a CTA that fires the transitionToNext function.
    // Normally this function is used to redirect the user to a specific route.
  }

  /**
   *  This task is performed when the user clicks the X button to close the FiF.
   */
  onAbortTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.EXIT);
    let result = await this.openAbortModalTask.perform();
    let shouldContinueAbort = result === 'confirm';
    if (shouldContinueAbort) {
      this.segment.track(ONBOARDING_TRACKING_EVENTS.EXIT_CONFIRMED);
      this.router.transitionTo('receivable-invoices.index');
      return;
    }
    return shouldContinueAbort;
  });

  openAbortModalTask = dropTask(
    async () =>
      await this.modals.open('popup/confirmation', {
        title: this.intl.t('flow-in-flow.modal.abort.title'),
        description: this.intl.t('receivable-invoices.onboarding.summary.leave-modal.description'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('btn.leave'),
      })
  );
}
