export default {
  "mt-16": "qjC",
  "title": "qjk",
  "avatar": "qjJ",
  "edit-organization-form": "qjs",
  "dropdown-content": "qjF",
  "city-block": "qjg",
  "zip-code-input": "qjD",
  "cancel-button": "qjV",
  "error-message": "qjL",
  "nature-of-operations": "qjT",
  "error": "qjz",
  "legal-code": "qja",
  "activity-type": "qjH"
};
