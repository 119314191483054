export default {
  "container": "qWI",
  "link-grey": "qWt",
  "slack-section-title": "qWM title-4 mb-8",
  "edit-channel-text": "qWP body-2 mb-16",
  "rule-section-title": "qWl title-4 mb-16",
  "rule-cards-container": "qWX",
  "edit-slack-container": "qWC",
  "rule-card": "qWk",
  "add-rule-container": "qWJ",
  "add-rule-link-button": "qWs",
  "add-rule-text": "qWF",
  "empty-state": "qWg",
  "empty-state-textblock": "qWD",
  "empty-state-title": "qWV title-2",
  "empty-state-description": "qWL body-2",
  "empty-state-illustration": "qWT",
  "rules": "qWz"
};
