export default {
  "container": "qbj",
  "form-container": "qbW",
  "preview-container": "qbu",
  "credit-note-preview-container": "qyS",
  "credit-note-preview": "qyc",
  "content": "qyq",
  "title": "qyZ",
  "tabs-container": "qyR",
  "close-button": "qyQ btn btn--icon-only btn--tertiary btn--large",
  "header": "qye",
  "without-tabs": "qyB"
};
