export default {
  "page-container": "qfx",
  "form": "qfw",
  "close-button": "qfN btn btn--icon-only btn--tertiary btn--large",
  "preview": "qfv",
  "preview-padding": "qfo",
  "title": "qfn",
  "header": "qfi",
  "without-tabs": "qfA",
  "form-section": "qfY",
  "form-footer": "qfh",
  "disclaimer-text": "qff"
};
