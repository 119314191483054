export default {
  "container": "qhG",
  "compact-header": "qhr",
  "visible": "qhb",
  "page-container": "qhy",
  "main": "qhU",
  "tabs": "qhj",
  "tabs-nav": "qhW",
  "tabs-panels": "qhu",
  "tabs-panel": "qfS body-2",
  "sidebar": "qfc",
  "prismic-content": "qfq"
};
