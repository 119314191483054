export default {
  "page-wrapper": "qNO",
  "container": "qNm",
  "sidebar": "qNp",
  "search-input": "qNx",
  "organizations-list": "qNw",
  "content": "qNN",
  "no-result": "qNv",
  "lottie-illustration": "qNo",
  "accounting-header": "qNn",
  "logo": "qNi",
  "accounting-title": "qNA"
};
