export default {
  "wrapper": "qKB",
  "header": "qKE",
  "main": "qKd",
  "frequency": "qKI body-1",
  "radiogroup": "qKt",
  "divider": "qKM",
  "option-wrapper": "qKP",
  "option-details-wrapper": "qKl",
  "option-label": "qKX",
  "option-name": "qKC",
  "option-details-text": "qKk",
  "spinner-wrapper": "qKJ",
  "spinner": "qKs"
};
