export default {
  "header": "qNl",
  "btn-preset": "qNX btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qNC",
  "statements-table": "qNk",
  "no-result": "qNJ",
  "lottie-illustration": "qNs",
  "wrapper": "qNF",
  "access-denied": "qNg",
  "access-denied-illustration": "qND",
  "application-card": "qNV",
  "presets-wrapper": "qNL",
  "error-wrapper": "qNT",
  "error-container": "qNz",
  "error-illustration": "qNa mb-32",
  "invoicing-info": "qNH"
};
