import { MEMBER_EDIT_FLOW_NAMES } from 'qonto/constants/member-edit-flow';
import AccountReceivableOnboardingFlowSetup from 'qonto/routes/flows/setup/account-receivable-onboarding/account-receivable-onboarding';
import AccountingDiscoveryFlowSetup from 'qonto/routes/flows/setup/accounting-discovery';
import ExternalAccountImportFlowSetup from 'qonto/routes/flows/setup/accounts/external/import';
import RemuneratedAccountCreationFlowSetup from 'qonto/routes/flows/setup/accounts/remunerated/creation';
import RemuneratedAccountFundingFlowSetup from 'qonto/routes/flows/setup/accounts/remunerated/funding';
import ApprovalWorkflowsFlowSetup from 'qonto/routes/flows/setup/approval-workflows/approval-workflows';
import CreateBudgetFlowSetup from 'qonto/routes/flows/setup/budgets/create-budget';
import EditBudgetFlowSetup from 'qonto/routes/flows/setup/budgets/edit-budget';
import CardSetPinKYCFlowSetup from 'qonto/routes/flows/setup/cards/kyc/set-pin';
import VirtualCardProvideKYCFlowSetup from 'qonto/routes/flows/setup/cards/kyc/virtual-card';
import CardNewDigitalFlowSetup from 'qonto/routes/flows/setup/cards/new/digital';
import CardNewAdvertisingFlowSetup from 'qonto/routes/flows/setup/cards/new/digital-secondary/advertising';
import CardNewFlashFlowSetup from 'qonto/routes/flows/setup/cards/new/digital-secondary/flash';
import CardNewVirtualFlowSetup from 'qonto/routes/flows/setup/cards/new/digital-secondary/virtual';
import CardsNewInviteeFlowSetup from 'qonto/routes/flows/setup/cards/new/invitee';
import CardsNewInviteePhysicalOneFlowSetup from 'qonto/routes/flows/setup/cards/new/invitee-physical-one';
import CardNewInviteeVirtualFlowSetup from 'qonto/routes/flows/setup/cards/new/invitee-virtual';
import CardNewPhysicalFlowSetup from 'qonto/routes/flows/setup/cards/new/physical';
import CardOnboardingFlowSetup from 'qonto/routes/flows/setup/cards/onboarding';
import CardResetPinFlowSetup from 'qonto/routes/flows/setup/cards/pin/reset';
import CardSetPinFlowSetup from 'qonto/routes/flows/setup/cards/pin/set';
import CardRenewPhysicalFlowSetup from 'qonto/routes/flows/setup/cards/renew/physical';
import CardReorderSetup from 'qonto/routes/flows/setup/cards/reorder';
import CardUpsellFlowSetup from 'qonto/routes/flows/setup/cards/upsell';
import ChargebackClaimFlowSetup from 'qonto/routes/flows/setup/chargeback-claim';
import CheckFlowSetup from 'qonto/routes/flows/setup/check';
import ConciergeFlowSetup from 'qonto/routes/flows/setup/concierge';
import ConnectApplicationsActivationFlowSetup from 'qonto/routes/flows/setup/connect/applications/activation';
import CreateTeamFlowSetup from 'qonto/routes/flows/setup/create-team';
import DirectDebitCollectionClientFlowSetup from 'qonto/routes/flows/setup/direct-debit-collections/client';
import DirectDebitSubscriptionFlowSetup from 'qonto/routes/flows/setup/direct-debit-collections/subscription';
import F24ManualDeclarationFlowSetup from 'qonto/routes/flows/setup/f24-manual-declaration';
import PayLaterApplicationFlowSetup from 'qonto/routes/flows/setup/financing/pay-later/application';
import InvoiceFlowSetup from 'qonto/routes/flows/setup/invoice';
import ManagementKycFlowSetup from 'qonto/routes/flows/setup/management-kyc';
import MandateFlowSetup from 'qonto/routes/flows/setup/mandate';
import MatchInvoiceFlowSetup from 'qonto/routes/flows/setup/match-invoice';
import MemberEditFlowSetup from 'qonto/routes/flows/setup/member/edit';
import MemberInviteFlowSetup from 'qonto/routes/flows/setup/member/invite';
import PagopaFlowSetup from 'qonto/routes/flows/setup/pagopa';
import PaymentActivationFlowSetup from 'qonto/routes/flows/setup/payment-activation';
import ProfileKYCFlowSetup from 'qonto/routes/flows/setup/profile/kyc';
import RequestExpenseReportSetup from 'qonto/routes/flows/setup/request/expense-report';
import RequestMileageFlowSetup from 'qonto/routes/flows/setup/request/mileage';
import RequestsCardsFlowSetup from 'qonto/routes/flows/setup/requests/cards';
import RequestsVirtualCardKycFlowSetup from 'qonto/routes/flows/setup/requests/virtual-card-kyc';
import SddActivationFlowSetup from 'qonto/routes/flows/setup/sdd-activation';
import SubscriptionChangeFlowSetup from 'qonto/routes/flows/setup/subscription';
import AddonFlowSetup from 'qonto/routes/flows/setup/subscription-addon';
import SubscriptionCloseFlowSetup from 'qonto/routes/flows/setup/subscription/close';
import FxTransferBeneficiaryFlowSetup from 'qonto/routes/flows/setup/transfers/fx/beneficiary';
import FxTransferFlowSetup from 'qonto/routes/flows/setup/transfers/fx/new';
import InternationalOutBeneficiaryFlowSetup from 'qonto/routes/flows/setup/transfers/international-out/beneficiary';
import InternationalOutFlowSetup from 'qonto/routes/flows/setup/transfers/international-out/new';
import SepaTransferBeneficiaryFlowSetup from 'qonto/routes/flows/setup/transfers/sepa/beneficiary';
import EditSepaTransferFlowSetup from 'qonto/routes/flows/setup/transfers/sepa/edit';
import SepaTransferFlowSetup from 'qonto/routes/flows/setup/transfers/sepa/new';
import SepaPayLaterTransferFlowSetup from 'qonto/routes/flows/setup/transfers/sepa/pay-later';

export const flowSetupClasses = {
  'addon-change': AddonFlowSetup,
  'card-new-advertising': CardNewAdvertisingFlowSetup,
  'card-new-digital': CardNewDigitalFlowSetup,
  'card-new-flash': CardNewFlashFlowSetup,
  'card-new-invitee': CardsNewInviteeFlowSetup,
  'card-new-invitee-physical-one': CardsNewInviteePhysicalOneFlowSetup,
  'card-new-invitee-virtual': CardNewInviteeVirtualFlowSetup,
  'card-new-physical': CardNewPhysicalFlowSetup,
  'card-new-virtual': CardNewVirtualFlowSetup,
  'card-renew-physical': CardRenewPhysicalFlowSetup,
  'card-onboarding': CardOnboardingFlowSetup,
  'card-reorder': CardReorderSetup,
  'card-reset-pin': CardResetPinFlowSetup,
  'card-set-pin': CardSetPinFlowSetup,
  'card-set-pin-kyc': CardSetPinKYCFlowSetup,
  'card-upsell': CardUpsellFlowSetup,
  check: CheckFlowSetup,
  'chargeback-claim': ChargebackClaimFlowSetup,
  concierge: ConciergeFlowSetup,
  'remunerated-account-creation': RemuneratedAccountCreationFlowSetup,
  'remunerated-account-funding': RemuneratedAccountFundingFlowSetup,
  'member-edit': MemberEditFlowSetup,
  [MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_ACCOUNT_PERMISSIONS]: MemberEditFlowSetup,
  [MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_EXPENSE_PERMISSIONS]: MemberEditFlowSetup,
  [MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_ROLE]: MemberEditFlowSetup,
  [MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_SCOPE]: MemberEditFlowSetup,
  [MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_OTHER_PERMISSIONS]: MemberEditFlowSetup,
  'member-invite': MemberInviteFlowSetup,
  'requests-cards': RequestsCardsFlowSetup,
  'requests-virtual-card-kyc': RequestsVirtualCardKycFlowSetup,
  'invoice-onboarding': InvoiceFlowSetup,
  'management-kyc': ManagementKycFlowSetup,
  'subscription-change': SubscriptionChangeFlowSetup,
  'subscription-close': SubscriptionCloseFlowSetup,
  pagopa: PagopaFlowSetup,
  mandate: MandateFlowSetup,
  'match-invoice': MatchInvoiceFlowSetup,
  'request-mileage': RequestMileageFlowSetup,
  'request-expense-report': RequestExpenseReportSetup,
  'profile-kyc': ProfileKYCFlowSetup,
  'virtual-card-provide-kyc': VirtualCardProvideKYCFlowSetup,
  'international-out': InternationalOutFlowSetup,
  'international-out-beneficiary': InternationalOutBeneficiaryFlowSetup,
  'fx-transfer': FxTransferFlowSetup,
  'fx-transfer-beneficiary': FxTransferBeneficiaryFlowSetup,
  'sepa-transfer': SepaTransferFlowSetup,
  'sepa-transfer-beneficiary': SepaTransferBeneficiaryFlowSetup,
  'edit-sepa-transfer': EditSepaTransferFlowSetup,
  'create-team': CreateTeamFlowSetup,
  'create-budget': CreateBudgetFlowSetup,
  'edit-budget': EditBudgetFlowSetup,
  'pay-later-application': PayLaterApplicationFlowSetup,
  'payment-activation': PaymentActivationFlowSetup,
  'f24-manual-declaration': F24ManualDeclarationFlowSetup,
  'sdd-activation': SddActivationFlowSetup,
  'external-account-import': ExternalAccountImportFlowSetup,
  'direct-debit-subscription': DirectDebitSubscriptionFlowSetup,
  'direct-debit-collection-client': DirectDebitCollectionClientFlowSetup,
  'pay-later-transfer': SepaPayLaterTransferFlowSetup,
  'approval-workflows': ApprovalWorkflowsFlowSetup,
  'connect-applications-activation': ConnectApplicationsActivationFlowSetup,
  'accounting-discovery': AccountingDiscoveryFlowSetup,
  'account-receivable-onboarding': AccountReceivableOnboardingFlowSetup,
};
