export default {
  "bank-accounts": "qUW l-app-content__wrapper",
  "header-wrapper": "qUu",
  "scrolled-top": "qjS",
  "header": "qjc l-app-content__page-header",
  "create-account-button": "qjq btn btn--primary",
  "main": "qjZ",
  "accounts": "qjR",
  "subtitle-container": "qjQ",
  "subtitle": "qje title-3 mb-16",
  "amount": "qjB body-2",
  "list--cashbeeSavings": "qjE"
};
