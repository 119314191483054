import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask, task, timeout } from 'ember-concurrency';

import { TRANSFER_EVENTS } from 'qonto/constants/listeners';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';

const REFETCH_TRANSFERS_EVENTS = [
  TRANSFER_EVENTS.INSTANT_DECLINED,
  TRANSFER_EVENTS.INSTANT_SETTLED,
  TRANSFER_EVENTS.INSTANT_TIMEOUT,
  TRANSFER_EVENTS.SETTLED,
];

export default class pendingTransfersRoute extends Route {
  @service abilities;
  @service homePage;
  @service notifierManager;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  queryParams = {
    highlight: { refreshModel: false },
    complete: { refreshModel: false },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
    bankAccounts: { refreshModel: true },
  };

  statusFilters = [
    'kyb_onhold',
    'pending',
    'pending_review',
    'pending_seizure',
    'processing',
    'validated', // Kantox status, it mean validated from Kantox POV
    'standing_processing',
  ];

  beforeModel(transition) {
    let canReadTransfers = this.abilities.can('read transfer');

    if (!canReadTransfers) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  model(params) {
    this.fetchDataTask.perform(params).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    this.fetchTotalCountTask.perform().catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    return {
      fetchTransfersTask: this.fetchDataTask,
      fetchTotalCountTask: this.fetchTotalCountTask,
    };
  }

  fetchDataTask = restartableTask(async params => {
    await timeout(DEBOUNCE_MS);
    return await this.fetchTask.perform(params);
  });

  fetchTotalCountTask = restartableTask(async () => {
    return await this.fetchTask.perform({ per_page: 1, status: null, bankAccounts: null });
  });

  fetchTask = task(
    async ({
      highlight,
      complete,
      page,
      per_page,
      sort_by,
      bankAccounts,
      status = this.statusFilters,
    }) => {
      let params = {
        highlight,
        complete,
        page,
        per_page,
        sort_by,
        filters: { status },
      };

      if (bankAccounts) {
        params.filters.bank_account_ids = bankAccounts.split(',');
      }

      let nonNullParams = filterParams(params);

      return await this.fetchTransfer(nonNullParams);
    }
  );

  fetchTransfer({ page, per_page, sort_by, filters }) {
    let organizationId = this.organizationManager.organization.id;

    let includes = ['beneficiaries', 'memberships'];

    let requiredParams = { filters, includes, organization_id: organizationId };
    let optionalParams = { page, per_page, sort_by };
    let mergedParams = { ...requiredParams, ...optionalParams };
    return this.store.query('transfer', mergedParams);
  }

  redirect(model, transition) {
    let { queryParams } = transition.to;
    let selectedBankAccount =
      this.organizationManager.accounts.find(
        item => get(item, 'id') === queryParams['bank-accounts']
      ) || this.organizationManager.currentAccount;
    if (selectedBankAccount.isClosed) {
      this.router.transitionTo('transfers.past');
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.fetchDataTask.cancelAll({ resetState: true });
      this.fetchTotalCountTask.cancelAll({ resetState: true });
      controller.setProperties({
        page: 1,
        highlight: null,
        complete: false,
        per_page: 25,
        sort_by: null,
        bankAccounts: '',
        sidebarOpened: false,
      });
    }
  }

  updateTransfer(payload) {
    let { object_id: id, object: transferParams } = payload;

    if (!this.store.peekRecord('transfer', id)) {
      return this.refresh();
    }

    // Websocket notification returns masked IBANs we do not want to use
    delete transferParams.iban;

    this.store.pushPayload({
      transfer: {
        id,
        ...transferParams,
      },
    });
  }

  refreshTransfers() {
    this.refresh();
  }

  activate() {
    super.activate(...arguments);

    REFETCH_TRANSFERS_EVENTS.forEach(eventName => {
      this.notifierManager.on(eventName, this, 'refreshTransfers');
    });

    this.notifierManager.on(TRANSFER_EVENTS.CREATED, this, 'updateTransfer');
  }

  deactivate() {
    super.deactivate(...arguments);

    REFETCH_TRANSFERS_EVENTS.forEach(eventName => {
      this.notifierManager.off(eventName, this, 'refreshTransfers');
    });

    this.notifierManager.off(TRANSFER_EVENTS.CREATED, this, 'updateTransfer');
  }
}
