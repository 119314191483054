import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Avatar, Badge, BadgeHighlight } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import {
  CONNECT_FEATURE_LEVELS,
  INTEGRATION_SOURCES,
  INTEGRATION_TYPES,
} from 'qonto/constants/connect';
import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { ApplicationSidebar } from 'qonto/react/components/connect/application-sidebar';

export default class HubApplicationDetailsController extends Controller {
  @service abilities;
  @service organizationManager;
  @service router;
  @service intl;
  @service connectManager;
  @service segment;
  @service subscriptionManager;

  @tracked showStickyMenu;

  @reads('organizationManager.organization.hasModularPricing') hasModularPricing; // TODO modularPricing cleanup

  BADGE_TYPE = BADGE_TYPE;

  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';
  source = INTEGRATION_SOURCES.MARKETPLACE;

  // React components
  applicationSidebar = ApplicationSidebar;
  avatar = Avatar;
  badge = Badge;
  badgeHighlight = BadgeHighlight;

  get canAccessGoldConnect() {
    return this.abilities.can('access gold connect');
  }

  get canAccessSilverConnect() {
    return this.abilities.can('access silver connect');
  }

  get canAccessAccountsPayable() {
    return this.abilities.can('access accounts payable connect');
  }

  get canAccessAccountsReceivable() {
    return this.abilities.can('access accounts receivable connect');
  }

  get tabs() {
    return {
      about: {
        active: 'about',
        title: this.intl.t('qonto-hub.connect.tabs.about'),
      },
      description: {
        active: 'description',
        title: this.intl.t('qonto-hub.connect.tabs.features'),
      },
      install: {
        active: 'install',
        title: this.intl.t('qonto-hub.connect.tabs.setup'),
      },
      settings: {
        active: 'settings',
        title: this.intl.t('qonto-hub.connect.tabs.settings'),
      },
    };
  }

  get displayedTabs() {
    let {
      body,
      how_to_install,
      integration_type,
      tray_solution_id,
      kombo_connect_provider_name,
      bank_id,
      waitlisted,
    } = this.model.prismicData;
    let tabs = [this.tabs.description];
    let hasHowToInstall = how_to_install.find(({ text }) => Boolean(text));
    let hasBody = body.find(({ text }) => Boolean(text));

    if (integration_type !== INTEGRATION_TYPES.partnerships && !waitlisted) {
      if (hasHowToInstall) {
        tabs.push(this.tabs.install);
      }

      if (hasBody) {
        tabs.push(this.tabs.about);
      }
    }

    if (
      integration_type === INTEGRATION_TYPES.internal &&
      !tray_solution_id &&
      !kombo_connect_provider_name &&
      !bank_id
    ) {
      tabs.push(this.tabs.settings);
    }

    return tabs.map(tab => ({ ...tab, route: `${this.detailsRouteName}.${tab.active}` }));
  }

  get higherPlanTooltip() {
    let { minimum_integration_level } = this.model.prismicData;

    if (minimum_integration_level === CONNECT_FEATURE_LEVELS.silver)
      return this.intl.t('qonto-hub.connect.to-discover-tooltip.silver');

    if (minimum_integration_level === CONNECT_FEATURE_LEVELS.gold)
      return this.intl.t('qonto-hub.connect.to-discover-tooltip.gold');
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial(
      this.model.prismicData.minimum_integration_level
    );
  }

  get backRoute() {
    return this.shouldTransitionBackToOrigin()
      ? this.model.fromTransition.name
      : 'settings.connect-hub.applications';
  }

  get backModels() {
    return this.shouldTransitionBackToOrigin()
      ? [...Object.values(this.model.fromTransition.params)]
      : [this.organizationManager.organization.slug];
  }

  get backQuery() {
    return this.shouldTransitionBackToOrigin() ? this.model.fromTransition.queryParams : {};
  }

  shouldTransitionBackToOrigin() {
    return (
      this.model.fromTransition &&
      !this.model.fromTransition.name.includes('details_loading') &&
      !this.model.fromTransition.name.includes('flow')
    );
  }

  @action handleIntersect(entries) {
    for (let entry of entries) {
      this.showStickyMenu = !entry.isIntersecting;
    }
  }

  @action trackCta(ctaId) {
    let trackingInfo = {
      name: this.model.prismicData?.name,
      cta_type: ctaId,
      integrationType: this.model.prismicData?.integration_type,
      legal_country: this.organizationManager.organization.legalCountry,
    };
    let trackerName = 'connect_app_details_cta_clicked';

    switch (ctaId) {
      case 'disconnect':
      case 'connect':
        this.segment.track(trackerName, {
          ...trackingInfo,
          solutionId: this.model.prismicData?.tray_solution_id,
        });
        break;
      case 'upsell':
        this.segment.track(trackerName, {
          ...trackingInfo,
          solutionId: this.model.prismicData?.tray_solution_id,
          trial_state: this.trialInfo ? TRACKING_TRIAL_STATE.FREE_TRIAL : TRACKING_TRIAL_STATE.NONE,
        });
        break;
      case 'redirect':
        this.segment.track(trackerName, trackingInfo);
        break;
    }
  }

  @action manageExternalBank() {
    let { bank_provider_connector_uuid: connectionId } = this.model.prismicData || {};
    if (!connectionId) return;

    this.router.transitionTo('settings.connections.banks', {
      queryParams: { highlight: connectionId },
    });
  }
}
