export default {
  "container": "qyW",
  "form-container": "qyu",
  "preview-container": "qUS",
  "pdf-preview-container": "qUc",
  "content": "qUq",
  "title": "qUZ",
  "tabs-container": "qUR",
  "close-button": "qUQ btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qUe",
  "header": "qUB",
  "without-tabs": "qUE"
};
