export default {
  "body": "qGJ",
  "members": "qGs",
  "members-results": "qGF",
  "members-list": "qGg",
  "members-title": "qGD caption-bold",
  "member": "qGV",
  "empty-illustration": "qGL",
  "empty-title": "qGT title-3",
  "empty-description": "qGz body-2",
  "invitable-members": "qGa",
  "details": "qGH",
  "pagination-footer": "qGO"
};
