export default {
  "container": "qbY",
  "form-container": "qbh",
  "title": "qbf",
  "title-border": "qbK",
  "tabs-container": "qbG",
  "preview-container": "qbr",
  "email-preview-container": "qbb",
  "close-button": "qby btn btn--icon-only btn--tertiary btn--large"
};
