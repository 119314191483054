export default {
  "page-wrapper": "qyn",
  "content-wrapper": "qyi",
  "content": "qyA",
  "period-field": "qyY",
  "format-options": "qyh",
  "format-option": "qyf",
  "format-radio": "qyK",
  "format-description": "qyG body-2"
};
