import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class BudgetsUpgradePlan extends Route {
  @service abilities;
  @service router;
  @service organizationManager;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service intl;
  @service sentry;
  @service segment;

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      handleAddOnRedirection: this.handleAddOnRedirection.bind(this),
      hasModularPricing: this.hasModularPricing,
      handleAddOnRedirectionTask: this.handleAddOnRedirectionTask,
    });
  }

  handleAddOnRedirectionTask = dropTask(async () => {
    this.segment.track('upsell_empty-state_clicked', {
      empty_state_name: 'budgets',
      price_plan: this.subscriptionManager.currentPricePlan.code,
      role: this.organizationManager.membership.role,
      trial_state: TRACKING_TRIAL_STATE.NONE,
    });
    await this.subscriptionManager.upgradeWithRecommendation('team_budget');
  });

  @action
  handleAddOnRedirection() {
    if (this.hasModularPricing) {
      this.handleAddOnRedirectionTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }
  }

  beforeModel() {
    if (this.abilities.can('use budget')) {
      return this.router.transitionTo('budgets');
    }
  }
}
