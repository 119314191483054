export default {
  "container": "qrs",
  "content": "qrF",
  "logos": "qrg",
  "logo": "qrD",
  "animation": "qrV",
  "title": "qrL title-2 mb-32",
  "avatar": "qrT",
  "dropdown": "qrz",
  "disabled": "qra",
  "organization": "qrH",
  "actions": "qrO"
};
