export default {
  "splash-landing": "qop",
  "container": "qox",
  "options": "qow",
  "physical": "qoN",
  "card-label": "qov",
  "physical-cta-container": "qoo",
  "physical-cta": "qon",
  "coming-soon": "qoi"
};
