export default {
  "supplier-invoices-page-header": "ZcA",
  "filters-container": "ZcY",
  "filters": "Zch",
  "bulk-actions": "Zcf",
  "item-count": "ZcK",
  "higher-index": "ZcG",
  "title-wrapper": "Zcr",
  "title": "Zcb",
  "subtitle": "Zcy",
  "table-container": "ZcU",
  "file-dropzone": "Zcj",
  "dropzone-visible": "ZcW",
  "header-actions": "Zcu",
  "header-wrapper": "ZqS"
};
