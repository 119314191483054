export default {
  "container": "ZSh",
  "header-cell": "ZSf caption-bold",
  "col-8": "ZSK",
  "table": "ZSG",
  "cell": "ZSr body-2",
  "cell-content": "ZSb",
  "row": "ZSy",
  "empty": "ZSU",
  "quick-actions": "ZSj",
  "card-infos": "ZSW",
  "card-digits": "ZSu",
  "empty-state": "ZcS"
};
