export default {
  "container": "qWb",
  "content": "qWy",
  "setup-box": "qWU",
  "setup-wizard": "qWj",
  "setup-success": "qWW",
  "setup-success-lottie": "qWu",
  "setup-close-btn": "quS",
  "tray-connection": "quc",
  "close-button": "quq"
};
