export default {
  "page-container": "qfU",
  "form": "qfj",
  "close-button": "qfW btn btn--icon-only btn--tertiary btn--large",
  "preview": "qfu",
  "preview-padding": "qKS",
  "title": "qKc",
  "header": "qKq",
  "without-tabs": "qKZ",
  "form-section": "qKR",
  "form-footer": "qKQ",
  "disclaimer-text": "qKe"
};
