import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  AVAILABLE_STATUSES,
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS,
} from 'qonto/constants/direct-debit-subscriptions';
import {
  getEmptyStateConfig,
  TAB_NAMES,
} from 'qonto/constants/empty-states/direct-debit-collections';
import { TRACKING_ORIGINS } from 'qonto/constants/empty-states/system';
import { getScheduleTypeLabel, getStatusLabel } from 'qonto/models/direct-debit-subscription';
import { EligibilityLossBanner } from 'qonto/react/components/direct-debit-collections/eligibility-loss-banner';
import { ErrorInfo } from 'qonto/utils/error-info';

export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 25;
export const DEFAULT_SORT_BY = 'next_collection_date:asc';

export default class DirectDebitCollectionsProcessingController extends Controller {
  @service notifierCounterManager;
  @service organizationManager;
  @service segment;
  @service emptyStates;
  @service intl;
  @service modals;
  @service sentry;
  @service toastFlashMessages;
  @service directDebitCollectionsManager;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  queryParams = [
    'highlight',
    'page',
    'perPage',
    'sortBy',
    'statusFilter',
    'scheduleTypeFilter',
    'search',
    { bankAccounts: 'bank-accounts' },
  ];

  @tracked highlight = '';
  @tracked page = DEFAULT_PAGE;
  @tracked perPage = DEFAULT_PER_PAGE;
  @tracked sortBy = DEFAULT_SORT_BY;
  @tracked statusFilter = null;
  @tracked scheduleTypeFilter = null;
  @tracked search = null;
  @tracked bankAccounts = null;

  EligibilityLossBanner = EligibilityLossBanner;

  get subscriptions() {
    return this.model.fetchSubscriptionsTask.lastSuccessful?.value ?? [];
  }

  get meta() {
    return this.subscriptions.meta;
  }

  get availableStatuses() {
    return AVAILABLE_STATUSES;
  }

  get availableScheduleTypes() {
    return Object.values(DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES);
  }

  get hasPendingRequests() {
    return this.notifierCounterManager.counter?.directDebitCollectionRequests > 0;
  }

  get isEmptyState() {
    return this.subscriptions.length === 0 && !this.model.fetchSubscriptionsTask.isRunning;
  }

  get isOrganizationFrenchOrGerman() {
    return (
      this.organizationManager.organization.legalCountry === 'FR' ||
      this.organizationManager.organization.legalCountry === 'DE'
    );
  }

  get emptyStateRevampOptions() {
    let { isEligible } = this.model;

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: isEligible,
      isEmptyGlobally: false,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: Boolean(this.statusFilter || this.scheduleTypeFilter || this.search),
      config: getEmptyStateConfig(this.intl, {}),
      customInputs: { tab: TAB_NAMES.PROCESSING },
    });
  }

  @action
  getStatusLabel(status) {
    return getStatusLabel(status, this.intl);
  }

  @action
  getScheduleTypeLabel(scheduleType) {
    return getScheduleTypeLabel(scheduleType, this.intl);
  }

  @action
  handleSelectSubscription(subscriptionId) {
    if (!subscriptionId) {
      this.highlight = null;
      return;
    }

    this.highlight = subscriptionId;

    this.segment.track('incoming-direct-debits_side-panel_opened');
  }

  @action
  handleSortBy(sortBy) {
    this.page = DEFAULT_PAGE;
    this.sortBy = sortBy;
  }

  @action
  handlePerPageChange(perPage) {
    this.page = DEFAULT_PAGE;
    this.perPage = perPage;
  }

  @action
  setupDirectDebitSubscription() {
    if (this.isEmptyState && this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, TRACKING_ORIGINS.HEADER);
    }

    this.directDebitCollectionsManager.handleRedirectionOnSetupCollection({
      redirectRoute: 'direct-debit-collections.processing',
    });
  }

  @action
  openCancelSubscriptionModal(subscription) {
    this.modals.open('direct-debit-collections/modals/cancel-subscription-modal', {
      subscription,
      confirmTask: this.cancelSubscriptionTask,
    });
  }

  cancelSubscriptionTask = dropTask(async (close, { subscription }) => {
    let subscriptionPreviousStatus = subscription.status;
    subscription.set('status', DIRECT_DEBIT_SUBSCRIPTION_STATUS.CANCELED);

    try {
      await subscription.save();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      subscription.set('status', subscriptionPreviousStatus);

      this.toastFlashMessages.toastError(
        this.intl.t('sdd-collections.toasts.error.cancel-generic')
      );
      return;
    }

    this.toastFlashMessages.toastSuccess(this.intl.t('sdd-collections.toasts.success.cancel'));
    this.resetQueryParams();

    await this.model.fetchSubscriptionsTask.perform({});
    await close();
  });

  @action
  openPaymentLinkModal(subscription) {
    this.transitionToRoute('direct-debit-collections.processing.payment-link', subscription.id);
  }

  resetQueryParams() {
    this.highlight = '';
    this.page = DEFAULT_PAGE;
    this.perPage = DEFAULT_PER_PAGE;
    this.sortBy = DEFAULT_SORT_BY;
    this.bankAccounts = null;
  }
}
