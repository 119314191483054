export default {
  "team": "qGm",
  "no-members": "qGp",
  "teams-header": "qGx",
  "header-search": "qGw",
  "search-bar": "qGN",
  "invite": "qGv body-2",
  "invite-text": "qGo",
  "empty-illustration": "qGn",
  "empty-title": "qGi title-3",
  "empty-description": "qGA body-2",
  "pagination-footer": "qGY",
  "filter-search": "qGh",
  "fullsize": "qGf",
  "tabs-container": "qGK",
  "tabs-separator": "qGG",
  "tooltip-wrapper": "qGr",
  "invite-cta": "qGb"
};
