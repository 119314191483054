import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask, task } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/clients';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ClientsIndexController extends Controller {
  @service store;
  @service emptyStates;
  @service intl;
  @service router;
  @service abilities;
  @service modals;
  @service toastFlashMessages;
  @service segment;
  @service sentry;

  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = 'name:asc';

  get currentParams() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
    };
  }

  get clients() {
    return this.model.clientsTask.lastSuccessful?.value.clients || [];
  }

  get highlightableItems() {
    let { clients } = this;
    let highlightedClient = this.highlightedClient.last || {};
    if (highlightedClient && !clients.includes(highlightedClient)) {
      return [...clients, highlightedClient];
    }
    return clients;
  }

  loadHighlightedClientTask = restartableTask(async clientId => {
    if (!clientId) {
      return null;
    }
    let client = this.store.peekRecord('client-hub', clientId);
    if (!client) {
      client = await this.store.findRecord('client-hub', clientId);
    }
    return client;
  });

  highlightedClient = trackedTask(this, this.loadHighlightedClientTask, () => [this.highlight]);

  loadHighlightedRemindersTask = restartableTask(async clientId => {
    if (!clientId) {
      return null;
    }
    if (this._highlightedRemindersClientId === clientId) {
      return this._highlightedReminders;
    }
    this._highlightedRemindersClientId = clientId;
    this._highlightedReminders = null;
    try {
      this._highlightedReminders = await this.store.queryRecord('reminders-configuration', {
        'filter[client_id]': clientId,
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('client-list.toasts.error.related-reminders'));
    }
    return this._highlightedReminders;
  });

  highlightedReminders = trackedTask(this, this.loadHighlightedRemindersTask, () => [
    this.highlight,
  ]);

  get highlightedReminderRules() {
    return this.highlightedReminders.value?.rules || [];
  }

  get closeSidebarId() {
    return 'close-sidebar';
  }

  get columnsToShow() {
    return {
      name: true,
      email: true,
    };
  }

  get displayEmptyState() {
    return !this.isLoading && !this.clients.length && !this.isError;
  }

  get isLoading() {
    return this.model.clientsTask.isRunning;
  }

  get isError() {
    return this.model.clientsTask.last.isError;
  }

  get emptyStateOptions() {
    let options = this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, { ctaCallback: this.emptyStateAddCTA }),
    });
    return options;
  }

  get invoiceStats() {
    return this.model.statsTask.lastSuccessful?.value.invoiceStats;
  }

  get quoteStats() {
    return this.model.statsTask.lastSuccessful?.value.quoteStats;
  }

  @action emptyStateAddCTA(origin) {
    this.emptyStates.trackCta(this.emptyStateOptions, origin);
    this.router.transitionTo('clients.new');
  }

  @action changePage(page) {
    this.page = page;
  }

  @action changePerPage(perPage) {
    this.perPage = perPage;
  }

  @action handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  @action onCreateInvoice(client) {
    this.trackQuickAction('create_invoice');
    let isInvoiceQuotaReached = this.invoiceStats?.quotasRemaining?.total === 0;
    if (isInvoiceQuotaReached) {
      return this.router.transitionTo('receivable-invoices.index');
    }

    return this.router.transitionTo('receivable-invoices.new', {
      queryParams: { customerId: client.id, origin: 'clients' },
    });
  }

  @action onCreateQuote(client) {
    this.trackQuickAction('create_quote');
    let isQuoteQuotaReached = this.quoteStats?.quotasRemaining?.total === 0;
    if (isQuoteQuotaReached) {
      return this.router.transitionTo('quotes.index');
    }

    return this.router.transitionTo('quotes.new', {
      queryParams: { customerId: client.id, origin: 'clients' },
    });
  }

  @action onCreateRecurringInvoice(client) {
    this.trackQuickAction('set_up_recurring_invoice');
    if (this.abilities.cannot('use invoice-subscription')) {
      return this.router.transitionTo('invoice-subscriptions.index');
    }

    return this.router.transitionTo('invoice-subscriptions.new', {
      queryParams: { customerId: client.id, origin: 'clients' },
    });
  }

  @action onEditClient(client) {
    this.trackQuickAction('edit_client');
    this.router.transitionTo('clients.client.edit', client.id);
  }

  @action onDeleteClient(client) {
    this.trackQuickAction('delete_client');
    this.modals.open('popup/destructive', {
      title: this.intl.t('receivable-invoices.customer-deletion.delete-modal.title'),
      description: this.intl.t('receivable-invoices.customer-deletion.delete-modal.description', {
        clientName: client.name,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('receivable-invoices.customer-deletion.delete-modal.delete.cta'),
      confirmTask: this.deleteClientTask,
      client,
    });
  }

  @action onSetReminders(client) {
    safeLocalStorage.setItem('automated-invoice-reminders-discovered', 'true');

    this.router.transitionTo('clients.client.reminders-configuration', client.id);
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  deleteClientTask = task(async (close, { client }) => {
    this.segment.track('client-list_client-deletion_confirmed');

    try {
      let clientId = client.id;
      await client.destroyRecord();
      this.store.peekRecord('customer', clientId)?.unloadRecord();
      this.toastFlashMessages.toastInfo(
        this.intl.t('receivable-invoices.customer-deletion.toast-success')
      );
      this.model.clientsTask
        .perform({ page: this.page, perPage: this.perPage, sortBy: this.sortBy })
        .catch(ignoreCancelation);
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      close();
    }
  });

  trackQuickAction(quick_action) {
    this.segment.track('client-list_quick-action_clicked', { quick_action });
  }
}
